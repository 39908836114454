document.addEventListener('DOMContentLoaded', () => {
    'use strict'

    /*==================================
     START THE PAGE LOADER
    ==================================*/
    window.addEventListener("load", function () {
        const loader = document.querySelector(".loader-page");
        loader.className += " hidden";
    });


    // // Get all links on the page
    // var links = document.querySelectorAll('a');

    // // Add a click event listener to each link
    // links.forEach(function (link) {
    //     link.addEventListener('click', function () {
    //         // Play the sound when the link is clicked
    //         document.getElementById('click-sound').play();

    //         // Store the fact that the sound has been played in session storage
    //         sessionStorage.setItem('soundPlayed', 'true');
    //     });
    // });

    // // Check if the sound has been played in the current session
    // if (sessionStorage.getItem('soundPlayed') === 'true') {
    //     // If the sound has been played, stop it from playing again
    //     document.getElementById('click-sound').pause();
    // }




    particlesJS("particles-js", {
        particles: {
            number: { value: 160, density: { enable: true, value_area: 2052 } },
            color: { value: "#ffffff" },
            shape: {
                type: "circle",
                stroke: { width: 0, color: "#000000" },
                polygon: { nb_sides: 5 },
            },
            opacity: {
                value: 1,
                random: true,
                anim: { enable: true, speed: 1, opacity_min: 0, sync: false }
            },
            size: {
                value: 2,
                random: true,
                anim: { enable: false, speed: 4, size_min: 0.3, sync: false }
            },
            line_linked: {
                enable: false,
                distance: 150,
                color: "#ffffff",
                opacity: 0.4,
                width: 1
            },
            move: {
                enable: true,
                speed: 1,
                direction: "none",
                random: true,
                straight: false,
                out_mode: "out",
                bounce: false,
                attract: { enable: false, rotateX: 600, rotateY: 600 }
            }
        },
        interactivity: {
            detect_on: "canvas",
            events: {
                onhover: { enable: false, mode: "repulse" },
                onclick: { enable: true, mode: "repulse" },
                resize: true
            },
            modes: {
                grab: { distance: 400, line_linked: { opacity: 1 } },
                bubble: { distance: 250, size: 0, duration: 2, opacity: 0, speed: 3 },
                repulse: { distance: 400, duration: 0.4 },
                push: { particles_nb: 4 },
                remove: { particles_nb: 2 }
            }
        },
        retina_detect: true
    });


    // /*==================================
    //  ANIMATION PROGRAMS
    // ==================================*/
    // var tabs = document.querySelectorAll(".img-programs img");
    // setInterval(function () {
    //     var i = Math.floor(Math.random() * tabs.length);
    //     var img = tabs[i];
    //     img.classList.add("animate");
    //     img.addEventListener("animationend", function () {
    //         tabs.forEach(function (image) {
    //             image.classList.remove("animate");
    //         });
    //     }, { once: true });
    // }, 3000);

    // /*==================================
    //  COUNTER UP
    // ==================================*/

    // function countItUp(numb) {
    //     var secondsLabel = document.querySelector(".count");
    //     var totalSeconds = 0;

    //     var countdown = setInterval(function(){
    //       totalSeconds++;
    //       secondsLabel.innerHTML = totalSeconds;

    //       if (totalSeconds >= numb) {
    //         clearInterval(countdown);
    //       } 
    //     }, 100);
    //   }
    //   countItUp(200);

    //   function countItUp2(numb) {
    //     var secondsLabel = document.querySelector(".count2");
    //     var totalSeconds = 0;

    //     var countdown = setInterval(function(){
    //       totalSeconds++;
    //       secondsLabel.innerHTML = totalSeconds;

    //       if (totalSeconds >= numb) {
    //         clearInterval(countdown);
    //       } 
    //     }, 100);
    //   }
    //   countItUp2(150);

    //   function countItUp3(numb) {
    //     var secondsLabel = document.querySelector(".count3");
    //     var totalSeconds = 0;

    //     var countdown = setInterval(function(){
    //       totalSeconds++;
    //       secondsLabel.innerHTML = totalSeconds;

    //       if (totalSeconds >= numb) {
    //         clearInterval(countdown);
    //       } 
    //     }, 100);
    //   }
    //   countItUp3(100);


    // // TEXT ANIMATION TYPED.JS
    // var typed = new Typed('.texTyped', {
    //     strings: [
    //         "مجموعة من الحلول البرمجية من أنظمة ومنصات وتطبيقات للقطاع الغير الربحي.",
    //         "منصة اللقاءات",
    //         "منصة التدريب الإلكتروني",
    //         "منصة  الإستشارات",
    //         "منح البرمجيات",
    //         "منصة الاجتماعات",
    //         "منصة تك",
    //         "منصة إدارة الفعاليات",
    //         "منصة العضويات",
    //         "منصة إدارة المنح",
    //         "متجر إلكتروني",
    //         "مايكروسوفت 365",
    //         "منصة كيــان"
    //     ],
    //     typeSpeed: 60,
    //     loop: true,
    //     backSpeed: 10,
    //     startDelay: 0,
    //     fadeOut: true,
    //     cursorChar: ""
    // });


});



// window.onload = function () {
//     const SW9 = new SiriWave({
//         style: "ios9",
//         container: document.getElementById("animation-siri"),
//         autostart: true,
//         amplitude: 0.6,
//     });
// };


